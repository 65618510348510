<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
    style="z-index: 20"
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocele(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { localeChanged } from "vee-validate";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data: function () {
    return {
      locales: [
        {
          locale: "tr",
          img: require("@/assets/images/flags/TR.png"),
          name: "Türkçe",
        },
        {
          locale: "en",
          img: require("@/assets/images/flags/GB.png"),
          name: "English",
        },
        {
          locale: "az",
          img: require("@/assets/images/flags/AZ.png"),
          name: "Azerbaycanca",
        },
        {
          locale: "ru",
          img: require("@/assets/images/flags/RU.png"),
          name: "Rusça",
        },
      ],
    };
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      if (this.$i18n.locale)
        return this.locales.find((l) => l.locale === this.$i18n.locale);
      else return this.locales[0];
    },
  },
  created() {
    const locale = localStorage.getItem("locale");
    if (locale == null) this.changeLocele("tr");
    if (locale) this.changeLocele(locale);
  },
  methods: {
    changeLocele(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
      localeChanged();
    },
  },
};
</script>

<style></style>
